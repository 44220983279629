import * as React from "react";
const bgBlueGreen = `url(/img/bg-hero.png)`;

const PageHeader = ({title,byline, center}) => {
  
    return (
      <div className="bg-cover pt-12 pb-4 px-3" > 
          <div className="text-gray-800 max-w-6xl mx-auto" >
            <div className={`pb-12 text-center ${center ? '' : 'lg:text-left'}`}>
              <div className="uppercase leading-normal tracking-widest font-semibold">{byline}</div>
              <h1 className="text-6xl mt-2 font-bold">{title}</h1>
            </div>
          </div>
      </div>
    );
};

export default PageHeader;